<template>
  <div
    class="Video"
    :class="{ hover: isHover, clickable: src }"
    @click="openVideoModal"
    @keypress.enter="openVideoModal">
    <div class="image">
      <Pic
        alt="Video thumbnail"
        class="thumbnail"
        :src="imageSrc || videoThumbnail" />
      <div
        class="play"
        @focusin="isHover = true"
        @focusout="isHover = false"
        @mouseleave="isHover = false"
        @mouseover="isHover = true">
        <Pic
          :alt="$t('play')"
          class="play-button"
          src="../../../assets/video-play.svg" />
      </div>
    </div>
    <Modal v-if="isOpen && !isExternal" id="Video-iframe">
      <iframe
        allow="accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture"
        allowfullscreen
        frameborder="0"
        :height="~~(width / ratio)"
        :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
        :title="title"
        :width="width" />
    </Modal>
  </div>
</template>

<script>
import { getters } from 'views/utils'

const videoRegex = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^"&'>?]+)/

export default {
  props: {
    imageSrc: String,
    title: { type: String, required: true },
    src: { type: String, required: true },
    isExternal: { type: Boolean, default: false },
  },
  data() {
    return {
      isHover: false,
      isOpen: false,
      ratio: 16 / 9,
    }
  },
  computed: {
    videoId() {
      const videoId = this.src.match(videoRegex)

      return videoId
        ? videoId[5]
        : null
    },
    videoThumbnail() {
      if (!this.videoId) return null

      return `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg`
    },
    width() {
      const limit = document.querySelector('#Video-iframe .limit')

      // some unnecessary recalculation to force vue refetch clientWidth
      /* eslint-disable sonarjs/no-identical-expressions */
      const maxWidth = limit
        ? limit.clientWidth * (this.screenWidth / this.screenWidth)
        : this.screenWidth
      /* eslint-enable sonarjs/no-identical-expressions */

      return Math.min(840, maxWidth)
    },
    ...getters('screenWidth'),
  },
  methods: {
    openVideoModal() {
      this.isOpen = !this.isOpen
      this.$emit('openVideo', this.videoId, this.width)
    },
  },
}
</script>

<style lang="scss">
.Video {
  > .image {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    > .thumbnail img {
      @include trans;
      background: $black-bis;
      width: 100%;
    }

    > .play {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .play-button img {
        @include trans;
        cursor: pointer;
        height: 60px;
        min-height: unset;
        width: 60px;
        @include md {
          width: auto;
        }
      }
    }

    &:hover {
      .thumbnail img {
        transform: scale(1.05);
      }

      .play img {
        transform: scale(1.1);
      }
    }
  }

  #Video-iframe {
    iframe {
      display: block;
    }
  }

  &.hover {
    .image > .thumbnail > img {
      filter: blur(4px);
    }
  }
}
</style>

<i18n>
{
  "de": {
    "play": "Video abspielen"
  },
  "en": {
    "play": "Play video"
  },
  "it": {
    "play": ""
  },
  "lt": {
    "play": "Žiūrėti video įrašą"
  },
  "pl": {
    "play": "Włącz wideo"
  }
}
</i18n>
