<template>
  <Card :id="`portfolio-${portfolio.id}`" class="CardPortfolio" :class="{ open: isOpen }">
    <Splitter :left="left" :right="12 - left">
      <Video
        v-if="portfolio.video_url"
        class="image"
        slot="left"
        :src="portfolio.video_url"
        :title="portfolio.location" />
      <Pic
        v-else
        :alt="portfolio.title"
        class="image"
        loading="lazy"
        slot="left"
        :src="portfolio.media.url" />

      <div slot="right" class="body">
        <div class="prop-list">
          <div v-for="(column, index) in propertiesColumns" :key="index" class="prop-column">
            <div v-for="prop in column" :key="prop.key" class="prop-item">
              <div class="label">
                {{ prop.label }}
              </div>
              <div class="value">
                {{ portfolio[prop.key] }} {{ prop.append }}
              </div>
            </div>
          </div>
        </div>

        <!--
        <BtnRound
          v-if="hasMoreInfo"
          data-cy="expand"
          @click="isOpen = !isOpen">
          <Icon :name="icon" />
        </BtnRound>
        -->
        <BtnShare :to="{ name: 'Portfolio', hash: `#portfolio-${portfolio.id}` }" />
      </div>
    </Splitter>
  </Card>
</template>

<script>
import { getters, mergeLabels } from '../../../utils'
import BtnShare from 'views/components/BtnShare'
import Splitter from 'views/components/Splitter'
import Video from 'views/components/Video'
import { scrollTo } from '../../../../router/smoothScroll'

export default {
  components: { BtnShare, Splitter, Video },
  props: {
    portfolio: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    icon() {
      return this.isOpen ? 'cross' : 'plus'
    },
    hasMoreInfo() {
      return !!this.portfolio.technologies
    },
    left() {
      return this.isOpen ? 5 : 7
    },
    properties() {
      return mergeLabels([
        { key: 'location' },
        { key: 'capacity', append: 'kW' },
        { key: 'installation_year' },
        { key: 'landscape_area', append: 'ha' },
        ...(this.isOpen ? [
          { key: 'technologies' },
          { key: 'pv_module_1' },
          { key: 'pv_module_2' },
          { key: 'mounting_system' },
        ] : []),
      ], this.$ta('properties'))
    },
    propertiesColumns() {
      return this.splitIntoRows(this.properties, { md: 4, sm: 2, xs: 4 })
    },
    ...getters('splitIntoRows'),
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.hash === `#portfolio-${this.portfolio.id}`) scrollTo(this.$el)
    }, 500)
  },
}
</script>

<style lang="scss">
.CardPortfolio {
  border: 0;

  .Splitter .Column {
    @include trans;
  }

  .Splitter-body {
    height: 100%;
    max-width: unset;
    padding: 2rem;
    width: 100%;
    @include lg {
      padding: 4rem;
    }
  }

  .prop-list {
    display: flex;
  }

  .prop-column {
    @include trans;
    text-align: left;
    width: 100%;
  }

  .prop-item {
    margin-bottom: 2rem;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .image > img,
  .image > .thumbnail,
  .image > .thumbnail > img {
    display: block;
    max-height: 500px;
    min-height: 360px;
    object-fit: cover;
    width: 100%;
    @include lg {
      min-height: 500px;
    }
  }

  .label {
    color: $black;
    font-size: $h8;
    opacity: 0.5;
  }

  .value {
    color: $black;
    font-size: $h35;
    margin-top: 0.5rem;
    min-height: 2.125rem;
    white-space: normal;
  }

  .BtnShare {
    bottom: 2rem;
    position: absolute;
    right: 3rem;
  }

  &.open {
    .prop-column {
      width: 45%;
    }
  }
}

</style>

<i18n>
{
  "de": {
    "properties": [
      "Standort",
      "Kapazität",
      "Installationsjahr",
      "Landschaftsgebiet",
      "Technologien",
      "PV-Module",
      "PV-Module",
      "Befestigungssysteme"
    ]
  },
  "en": {
    "properties": [
      "Location",
      "Capacity",
      "Installation Year",
      "Landscape Area",
      "Technologies",
      "PV modules",
      "PV modules",
      "Mounting Systems"
    ]
  },
  "it": {
    "properties": [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    ]
  },
  "lt": {
    "properties": [
      "Vieta",
      "Galia",
      "Įrengimo metai",
      "Plotas",
      "Technologijos",
      "Saulės moduliai",
      "Saulės moduliai",
      "Montavimo sistemos"
    ]
  },
  "pl": {
    "properties": [
      "Lokalizacja",
      "Moc",
      "Rok instalacji",
      "Obszar krajobrazowy",
      "Technologie",
      "Moduły PV",
      "Moduły PV",
      "System montażowy"
    ]
  }
}
</i18n>
