<template>
  <div id="Portfolio">
    <Hero :formats="['webp']" src="../../../assets/portfolio-hero.jpg">
      <Headline>
        {{ $t('title') }}
      </Headline>
      <Row>
        <Column :lg="6">
          <h2 v-html="$t('title')" />
        </Column>
        <Column :lg="6">
          <div class="hero-side-text">
            <p>{{ $t('side', snippetsValues) }}</p>
          </div>
        </Column>
      </Row>
      <HeroScrollDown hash="#MapPlants" slot="outside" />
    </Hero>
    <MapPlants />
    <section id="Portfolio-entries">
      <Wrap>
        <!-- <Row>
          <Column :md="6"> -->
        <h2>{{ $t('titleEntries') }}</h2>
        <!-- </Column> -->
        <!-- <Column :md="6"> -->
        <!--
            <div class="filters">
              <Filters
                :activeIndex.sync="activeTypeIndex"
                :options="officeTypes" />
            </div>
            -->
        <!-- </Column>
        </Row> -->

        <!--
        <Empty v-if="!portfolios.length">
          {{ $t('none') }}
        </Empty>
        -->

        <CardPortfolio
          v-for="portfolio in portfolios"
          :key="portfolio.id"
          :portfolio="portfolio" />

        <PaginationShowMore
          v-if="portfoliosCount > limit"
          :limit.sync="limit"
          name="portfolio"
          :total="portfoliosCount" />
        <div v-else id="PaginationShowMore">
          <Route
            class="Btn show-more inv"
            :to="{ name: 'Portfolio', hash: '#MapPlants' }">
            {{ $t('more') }}
          </Route>
        </div>
      </Wrap>
    </section>
  </div>
</template>

<script>
import { actions, getters, mergeLabels } from 'views/utils'
import CardPortfolio from './components/CardPortfolio'
// import Filters from 'views/components/Filters'
import Hero from 'views/components/Hero'
import HeroScrollDown from 'views/components/HeroScrollDown'
import MapPlants from 'views/components/MapPlants'
import Meta from 'views/mixins/Meta'
import PaginationShowMore from 'views/components/PaginationShowMore'

export default {
  mixins: [
    Meta(require('@/assets/social/portfolio.jpg')),
  ],
  components: { CardPortfolio, Hero, HeroScrollDown, MapPlants, PaginationShowMore },
  data() {
    return {
      activeRangeIndex: 0,
      rangesValues: [
        {
          doIgnore: true,
        },
        {
          to: 1000,
        },
        {
          from: 1000,
          to: 2000,
        },
        {
          from: 2000,
        },
      ],
      limit: 10,
    }
  },
  computed: {
    activeRange() {
      return this.ranges[this.activeRangeIndex]
    },
    ranges() {
      return mergeLabels(this.rangesValues, this.$ta('ranges'))
    },
    portfolios() {
      const query = {
        $sort: { id: 1 },
        $limit: this.limit,
      }

      if (!this.activeRange.doIgnore) {
        const { from, to } = this
          .ranges
          .find(({ label }) => label === this.activeRange)

        Object.assign(query, {
          capacity: {
            ...(from ? { $gte: from } : {}),
            ...(to ? { $lte: to } : {}),
          },
        })
      }

      return this.query('portfolio', query)
    },
    portfoliosCount() {
      return this.query('portfolio').length
    },
    ...getters('snippetsValues'),
  },
  methods: actions('FETCH_PORTFOLIO', 'FETCH_COUNTRY'),
  beforeMount() {
    this.LOAD(this.FETCH_PORTFOLIO())
    this.LOAD(this.FETCH_COUNTRY())
  },
}
</script>

<style lang="scss">
#Portfolio {
  background-image: url('../../../assets/ellipses.png');
  background-position: right;
  background-size: cover;

  h1 {
    margin-bottom: 4rem;
  }

  .Hero-body {
    @include lg {
      padding-top: 8rem;
    }

    .Headline {
      color: $white;
    }

    .hero-side-text {
      @include lg {
        padding-left: 20%;
      }
    }
  }

  .HeroScrollDown {
    background: $white;
    color: $main;
  }
}

#Portfolio-entries {
  min-height: 6rem;
  position: relative;

  h2 {
    margin: 0 0 3rem;
  }

  .Empty {
    margin-top: 4rem;
    @include md {
      margin: 0;
    }
  }
}
</style>

<i18n>
{
  "de": {
    "meta": {
      "description": "SIG verfügt über eine Erfolgsbilanz von {projects} separaten Projekten mit einer Gesamtkapazität von {constructedMw} MW. {developingGw} GW an Projekten befinden sich derzeit in der fortgeschrittenen Entwicklungsphase. ",
      "title": "Portfolio"
    },
    "more": "Zeig mehr",
    "none": "Keine Portfolio-Elemente gefunden",
    "ranges": [
      "Alle",
      "0 - 1000 kW",
      "1000 - 2000 kW",
      "2000+ kW"
    ],
    "side": "SIG verfügt über eine Erfolgsbilanz von {projects} separaten Projekten mit einer Gesamtkapazität von {constructedMw} MW. {developingGw} GW an Projekten befinden sich derzeit in der fortgeschrittenen Entwicklungsphase. ",
    "title": "Unser Portfolio",
    "titleEntries": "Fertiggestellte Projekte"
  },
  "en": {
    "meta": {
      "description": "SIG has a track record of more than {projects} separate projects delivering a total of {constructedMw} MW capacity. {developingGw} GW of projects are currently under the advanced development phase.",
      "title": "Portfolio"
    },
    "more": "Show more",
    "none": "No portfolio items found",
    "ranges": [
      "All",
      "0 - 1000 kW",
      "1000 - 2000 kW",
      "2000+ kW"
    ],
    "side": "SIG has a track record of more than {projects} separate projects delivering a total of {constructedMw} MW capacity. {developingGw} GW of projects are currently under the advanced development phase.",
    "title": "Our portfolio",
    "titleEntries": "Completed Projects"
  },
  "it": {
    "meta": {
      "description": "",
      "title": ""
    },
    "more": "",
    "none": "",
    "ranges": [
      "",
      "",
      "",
      ""
    ],
    "side": "",
    "title": "",
    "titleEntries": ""
  },
  "lt": {
    "meta": {
      "description": "SIG įgyvendintų projektų bendra galia siekia {constructedMw} MW. Šiuo metu įmonė vysto {developingGw} GW+ galios projektus.",
      "title": "Įgyvendinti projektai"
    },
    "more": "Daugiau",
    "none": "Nerasta projektų",
    "ranges": [
      "Visi",
      "0 - 1000 kW",
      "1000 - 2000 kW",
      "2000+ kW"
    ],
    "side": "SIG įgyvendintų projektų bendra galia siekia {constructedMw} MW. Šiuo metu įmonė vysto {developingGw} GW+ galios projektus.",
    "title": "Įgyvendinti projektai",
    "titleEntries": "Įgyvendinti projektai"
  },
  "pl": {
    "meta": {
      "description": "SIG zrealizowała {projects} projektów o łącznej mocy {constructedMw} MW. Obecnie prowadzone projekty rzędu {developingGw} GW są na zaawansowanych etapach.",
      "title": "Zrealizowane projekty"
    },
    "more": "Pokaż więcej",
    "none": "Nie znaleziono w portfolio",
    "ranges": [
      "Wszystko",
      "0 - 1000 kW",
      "1000 - 2000 kW",
      "2000+ kW"
    ],
    "side": "SIG zrealizowała {projects} projektów o łącznej mocy {constructedMw} MW. Obecnie prowadzone projekty rzędu {developingGw} GW są na zaawansowanych etapach.",
    "title": "Nasze projekty",
    "titleEntries": "Zrealizowane projekty"
  }
}
</i18n>
